<template>
  <div class="container mescroll-touch">
    <img class="menuIcon" src="@/assets/images/menu_fhxcl_icon@2x.png" @click="sidebarShow = true" />
    <div class="content">
      <div class="box" @click="toList('fhxcl_yggzlcc', 'fhxcl|fhxcl_yggzlcc|', '高张力层次')">
        <div class="left">
          <img class="icon" src="@/assets/images/gzlcc_fhxcl_icon@2x.png" />
          <p class="title">高张力层次</p>
        </div>
        <div class="right">
          <p class="title">鱼骨 • 高张力层次解决方案</p>
          <p class="describe">鱼骨线压制成刺工艺不损伤线芯，目前市面上唯一获得NMPA认可的可以使用在缝合筋膜等高张力层次的抗菌免打结缝线。</p>
        </div>
      </div>
      <div class="box" @click="toList('fhxcl_lxjxfhcc', 'fhxcl|fhxcl_lxjxfhcc|', '精细缝合层次')">
        <div class="left">
          <img class="icon" src="@/assets/images/jxfhcc_fhxcl_icon@2x.png" />
          <p class="title">精细缝合层次</p>
        </div>
        <div class="right">
          <p class="title">螺旋 • 精细缝合层次解决方案</p>
          <p class="describe">螺旋切割工艺，多型号和长度的单针及双针款抗菌免打结缝线，满足多样缝合需求。</p>
        </div>
      </div>
    </div>
    <Sidebar :sidebarShow="sidebarShow" @popupClose="popupClose" />
  </div>
</template>

<script>
import mixin from '../../common/mixin/mixins'
import Sidebar from '../../components/sidebar.vue'

export default {
  name: 'SuturePrefecture',
  data() {
    return {
      sidebarShow: false
    }
  },
  mixins: [mixin],
  components: {
    Sidebar
  },
  methods: {
    popupClose(val) {
      this.sidebarShow = val;
    },
    toList(Code, TCode, name) {
      this.BuriedPoint(1, name, TCode, '/sutureList');
      this.$router.push({
        path: 'sutureList',
        query: {
          Code: Code,
          TCode: TCode
        }
      });
    }
  }
}
</script>

<style lang="scss" scoped>
.container {
  position: relative;
  width: 100%;
  height: 100%;
  padding: 30px;
  box-sizing: border-box;
  background-image: url('../../assets/images/bg_fhxcl@2x.png');
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-color: #F9F9F9;
  .menuIcon {
    position: absolute;
    top: 25px;
    left: 22px;
    width: 30px;
    height: 30px;
  }
  .content {
    margin-top: 90%;
    .box {
      display: flex;
      padding: 15px 0;
      background-color: #FFFFFF;
      border-radius: 10px;
      .left {
        position: relative;
        width: 110px;
        .icon {
          display: block;
          width: 70px;
          height: 70px;
          margin: 0 auto 10px;
        }
        .title {
          font-size: 14px;
          color: #333333;
          text-align: center;
        }
      }
      .left::after {
        content: '';
        width: 1px;
        height: 100%;
        position: absolute;
        top: 0;
        right: 0;
        background: linear-gradient(to bottom, #9E8FD8, #FCEB8F);
      }
      .right {
        flex: 1;
        padding: 0 14px;
        .title {
          font-size: 14px;
          color: #333333;
          font-weight: bold;
          margin-bottom: 12px;
        }
        .describe {
          font-size: 11px;
          color: #9475E0;
        }
      }
    }
    .box:not(:last-child) {
      margin-bottom: 40px;
    }
  }
}
</style>